import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';

import { StateService } from '../../providers/state.service';

@Component({
    selector: 'kb-wishlist-link',
    templateUrl: './wishlist-link.component.html',
    styleUrls: ['./wishlist-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [RouterLink, FontAwesomeModule],
})
export class WishlistLinkComponent {
    @Input() showLabel = false;
    readonly heart = faHeart;

    constructor(private stateService: StateService) {
    }

    close() {
        this.stateService.setState('mobileNavMenuIsOpen', false);
    }
}
