import { AsyncPipe, NgIf, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle as faUserCircleSolid } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';

import { ActiveCustomerFragment } from '../../../common/gql/graphql';
import { ActiveService } from '../../providers/active-order/active.service';
import { StateService } from '../../providers/state.service';

@Component({
    selector: 'vsf-account-link',
    templateUrl: './account-link.component.html',
    styleUrls: ['./account-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        FontAwesomeModule,
        AsyncPipe,
        SlicePipe,
    ],
})
export class AccountLinkComponent implements OnInit {
    activeCustomer$: Observable<ActiveCustomerFragment | undefined>;
    readonly userCircleSolid = faUserCircleSolid;
    readonly userCircleRegular = faUserCircle;

    @Input() showLabel = false;

    constructor(private activeService: ActiveService, private stateService: StateService) {}

    ngOnInit() {
        this.activeCustomer$ = this.activeService.activeCustomer$;
    }

    close() {
        this.stateService.setState('mobileNavMenuIsOpen', false);
    }
}
