<div *ngIf='activeCustomer$ | async as customer; else notLoggedIn'>
    <a class='btn-icon' [routerLink]="['/account']" (click)="close()">
        <fa-icon [icon]='userCircleSolid'></fa-icon>
        <span class='hidden md:inline-block text-sm ml-2 text-gray-600'>My Account</span>
    </a>
    <div class='hidden md:block text-xs text-primary-700 text-right pr-1'>{{ customer.firstName | slice:0:1 }} {{ customer.lastName }}</div>
</div>
<ng-template #notLoggedIn>
    <a class='btn-icon relative block min-w-8' [routerLink]="['account/sign-in']" (click)="close()">
        <fa-icon [icon]='userCircleRegular'></fa-icon>
        <span class='md:inline-block text-sm ml-2 text-gray-600' [class.hidden]="!showLabel">Sign In</span>
    </a>
</ng-template>
