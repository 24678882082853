<kb-json-ld *ngIf="orgJsonLdData$ | async as orgJsonLdData" [json]="orgJsonLdData"></kb-json-ld>
<kb-json-ld *ngIf="searchJsonLdData$ | async as searchJsonLdData" [json]="searchJsonLdData"></kb-json-ld>
<vsf-layout class="relative">
    <vsf-layout-header *ngIf="!(isCheckoutFlow$ | async)">
        <kb-announcement
            *ngFor="let announcement of globalAnnouncements$ | async"
            [announcement]="announcement"
        >
        </kb-announcement>
        <div class="max-w-7xl mx-auto">
            <div class="header-layout gap-y-2 md:gap-y-0 gap-x-2 pl-2 pr-2 py-2 xl:pl-0 align-center">
                <div class="header-layout-menu flex items-center justify-start">
                    <kb-mobile-menu-toggle class="md:hidden flex pt-1 mr-2"></kb-mobile-menu-toggle>
                </div>
                <div class="header-layout-logo flex justify-center">
                    <a [routerLink]="['/']" class="max-w-[165px] lg:max-w-none inline-block">
                        <img
                            src="/assets/images/kbas-logo-new.svg"
                            alt="Bromleys Art Supplies logo"
                            width="250"
                            height="85"
                        />
                    </a>
                </div>
                <div class="header-layout-buttons flex items-baseline justify-end pr-2 mt-3">
                    <vsf-account-link class="flex items-center mr-1"></vsf-account-link>
                    <kb-wishlist-link class="hidden md:flex items-center"></kb-wishlist-link>
                    <vsf-cart-toggle class="flex items-center" (toggle)="openCartDrawer()"></vsf-cart-toggle>
                </div>

                <div class="header-layout-search flex flex-grow space-x-1 md:space-x-4 items-baseline">
                    <div class="flex-grow flex justify-end">
                        <vsf-product-search-bar
                            [autoSearch]="true"
                            (resultClick)="temporarilyDisableCollectionsMenu()"
                            class="max-w-lg flex items-center flex-1"
                        ></vsf-product-search-bar>
                    </div>

                </div>
            </div>

        </div>
        <div class="">
            <div class="max-w-7xl mx-auto" id="content-container">
                <vsf-collections-menu
                    class="border-t border-t-2 mt-4 py-2 border-primary-200 hidden md:block"
                    [disabled]="isCollectionsMenuDisabled$ | async"
                    [collections]="menuItems$ | async"
                ></vsf-collections-menu>
            </div>
        </div>
    </vsf-layout-header>
    <kb-side-tray [expanded]="mobileNavVisible$ | async" (close)="closeMobileNav()">
        <div class="flex flex-col gap-y-3 px-4 pb-3">
            <kb-display-currency-select
                class="border-b-2 border-solid pl-1 py-2"
            ></kb-display-currency-select>
            <vsf-account-link class="border-b-2 border-solid py-2" [showLabel]="true"></vsf-account-link>
            <kb-wishlist-link class="border-b-2 border-solid py-2" [showLabel]="true"></kb-wishlist-link>
        </div>
        <kb-collections-menu-mobile
            class="block lg:hidden bg-white shadow-lg"
            [collections]="menuItems$ | async"
        ></kb-collections-menu-mobile>
    </kb-side-tray>
    <kb-side-tray [expanded]="cartDrawerVisible$ | async" (close)="closeCartDrawer()" trayClasses="max-w-2xl">
        <vsf-cart-drawer></vsf-cart-drawer>
    </kb-side-tray>
    <div class="mx-auto min-h-screen">
        <router-outlet></router-outlet>
    </div>
    <vsf-layout-footer> </vsf-layout-footer>
</vsf-layout>
<kb-livechat></kb-livechat>
